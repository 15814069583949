import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FlipkartOffersComponent} from './flipkart-offers/flipkart-offers.component';

const routes: Routes = [
  {path:'', component: FlipkartOffersComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FlipkartService {

  constructor(
    private _http:HttpClient
  ) { }

  getOffers() {
    return this._http.get('https://node-cash-chills.now.sh/api/cc/fk');
  }

}

import { Component, OnInit } from "@angular/core";
import { FlipkartService } from "./flipkart.service";
import { map, shareReplay } from "rxjs/operators";

@Component({
  selector: "cc-flipkart-offers",
  templateUrl: "./flipkart-offers.component.html",
  styleUrls: ["./flipkart-offers.component.scss"]
})
export class FlipkartOffersComponent implements OnInit {
  constructor(private _flipkart: FlipkartService) {}

  isOffersLoaded: boolean = false;
  offers;

  ngOnInit() {
    this._flipkart.getOffers().pipe(shareReplay(1)).subscribe((data: any) => {
      let okdt = JSON.parse(data);
      this.offers = okdt.allOffersList;
      this.isOffersLoaded = true;
      // console.log("data", this.offers);
    });
  }
}


import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeHypen' })
export class RemoveHypen implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.replace(/-/g, " ");
  }
}